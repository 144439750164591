import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import './form.styl';
import desktopRegisterMail from '../../assets/img/register/desktopRegisterMail.png';

class RegisterSuccess extends Component {
  componentWillUnmount() {
    const { resetEmailValidation } = this.props;
    resetEmailValidation();
  }

  render() {
    const {
      wordings: {
        register: { finalise_register },
      },
    } = this.props;

    return (
      <Fragment>
        <h5>{finalise_register}</h5>
        <div className="image-container">
          <img src={desktopRegisterMail} alt="Mail" />
        </div>
      </Fragment>
    );
  }
}

RegisterSuccess.propTypes = {
  wordings: PropTypes.object.isRequired,
  resetEmailValidation: PropTypes.func.isRequired,
};

export default RegisterSuccess;
