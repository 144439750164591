import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Field extends Component {
  state = {
    selectorsOpen: false,
  };

  toggleSelector = (bool = null) => () => {
    this.setState(state => ({
      selectorsOpen: bool !== null ? bool : !state.selectorsOpen,
    }));
  };

  renderSelector = () => {
    const { name, onChange, selectorValues } = this.props;

    return (
      <div className="selector-wrapper">
        {selectorValues.map(data => (
          <div
            key={data}
            className="select-option"
            onClick={() => {
              this.toggleSelector(false)();
              onChange(name, data);
            }}
          >
            <span>{data}</span>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const {
      name,
      inputType,
      label,
      wrapperClassName,
      value,
      onChange,
      errors,
      disabled,
      keyPressed,
      selectorValues,
    } = this.props;
    const { selectorsOpen } = this.state;

    return (
      <div className={`input-text-wrapper ${wrapperClassName}`}>
        <div onClick={selectorValues.length ? this.toggleSelector() : null}>
          <input
            value={value}
            name={name}
            className={`${value && value.length ? ' not-empty' : ''}${
              errors && errors.length ? ' error' : ''
            }${selectorValues.length ? ' pointer' : ''}`}
            type={inputType}
            onChange={
              !selectorValues.length
                ? e => {
                    onChange(name, e.target.value);
                  }
                : null
            }
            disabled={disabled}
            onKeyDown={keyPressed ? e => keyPressed(e) : null}
          />
          <label htmlFor={name}>{label}</label>
        </div>
        {selectorValues.length && selectorsOpen ? this.renderSelector() : null}
      </div>
    );
  }
}

Field.defaultProps = {
  wrapperClassName: '',
  errors: [],
  disabled: false,
  onChange: () => {},
  selectorValues: [],
};

Field.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  inputType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  selectorValues: PropTypes.arrayOf(PropTypes.string),
};

export default Field;
