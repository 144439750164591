/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import {
  NavBar,
  Menu,
  Footer,
  Basket,
  pageWithNavigation,
} from '../components/common';
import SectionRegister from '../components/register';
import { noAuthPages } from '../components/common/page/noAuthPages';

class RegisterPage extends Component {
  render() {
    const {
      data: {
        wordings: { fr: wordings },
      },
    } = this.props;

    return (
      <Layout
        title={wordings.register.page_title}
        description={wordings.register.description}
      >
        <NavBar withLogo basketClassName="yellow" />
        <Basket />
        <Menu />
        <SectionRegister wordings={wordings} />
        <Footer />
      </Layout>
    );
  }
}

export const query = graphql`
  query RegisterPageQuery {
    wordings {
      fr {
        register {
          page_title
          description
          title
          sub_title
          email_label
          password_label
          create_account
          finalise_register
          accept_cgu
          cgu
        }
        forms {
          charte
          charte_link
        }
      }
    }
  }
`;

RegisterPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default pageWithNavigation(noAuthPages(RegisterPage));
