/* eslint-disable import/prefer-default-export,react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import { tryToken } from '../../../actions/shopActions';

const mapStateToProps = ({
  app: { isTokenValid },
  account: { access_token },
}) => ({
  access_token,
  isTokenValid,
});

export const noAuthPages = WrappedComponent =>
  connect(
    mapStateToProps,
    { tryTokenAction: tryToken }
  )(
    class extends Component {
      state = {
        canRenderPage: false,
      };

      componentWillMount() {
        const { tryTokenAction, access_token } = this.props;
        if (access_token !== '') {
          tryTokenAction(access_token, true);
        } else {
          this.setState(state => ({
            ...state,
            canRenderPage: true,
          }));
        }
      }

      componentDidUpdate(prevProps) {
        const { isTokenValid, access_token } = this.props;
        if (prevProps.isTokenValid === null && isTokenValid) {
          navigate('product');
        }
        if (
          (prevProps.isTokenValid === null && isTokenValid === false) ||
          (prevProps.access_token !== '' && access_token === '')
        ) {
          this.setState(state => ({
            ...state,
            canRenderPage: true,
          }));
        }
      }

      render() {
        const { canRenderPage } = this.state;
        if (!canRenderPage) {
          return null;
        }
        return <WrappedComponent {...this.props} />;
      }
    }
  );
