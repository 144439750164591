import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import {
  handleLogin,
  resetEmailValidation,
} from '../../actions/accountActions';
import { createAccount } from '../../actions/shopActions';

import RegisterSuccess from './registeredSuccess';
import RegisterForm from './registerForm';

import './form.styl';

class SectionRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      cguAccepted: false,
    };

    this.intervalRef = null;
  }

  componentWillReceiveProps(nextProps) {
    const { registered, authenticated, cart } = nextProps;
    if (registered && registered !== this.props.registered) {
      this.intervalRef = setInterval(() => {
        this.props.handleLoginAction(
          this.state.email,
          this.state.password,
          'email_validated'
        );
      }, 3000);
    }
    if (authenticated && authenticated !== this.props.authenticated) {
      clearInterval(this.intervalRef);
      navigate(cart.nbItems > 0 ? 'checkoutShipping' : 'product');
    }
  }

  onInputChange = (name, value) => {
    this.setState(state => ({
      ...state,
      [name]: value,
    }));
  };

  toggleCGU = () => {
    this.setState(state => ({
      ...state,
      cguAccepted: !state.cguAccepted,
    }));
  };

  render() {
    const {
      createAccountAction,
      registerErrors,
      wordings,
      registered,
      resetEmailValidationAction,
    } = this.props;
    const { email, password, cguAccepted } = this.state;

    return (
      <section className="register">
        <h4>{wordings.register.title}</h4>
        {registered ? (
          <RegisterSuccess
            wordings={wordings}
            resetEmailValidation={resetEmailValidationAction}
          />
        ) : (
          <RegisterForm
            registerErrors={registerErrors}
            wordings={wordings}
            createAccountAction={createAccountAction}
            password={password}
            email={email}
            onInputChange={this.onInputChange}
            toggleCGU={this.toggleCGU}
            cguAccepted={cguAccepted}
          />
        )}
      </section>
    );
  }
}

SectionRegister.propTypes = {
  resetEmailValidationAction: PropTypes.func.isRequired,
  handleLoginAction: PropTypes.func.isRequired,
  createAccountAction: PropTypes.func.isRequired,
  registerErrors: PropTypes.object.isRequired,
  wordings: PropTypes.object.isRequired,
  registered: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool.isRequired,
  cart: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({
  account: { registerErrors, registered, authenticated },
  shop: { cart },
}) => ({
  registerErrors,
  registered,
  authenticated,
  cart,
});

export default connect(
  mapStateToProps,
  {
    createAccountAction: createAccount,
    handleLoginAction: handleLogin,
    resetEmailValidationAction: resetEmailValidation,
  }
)(SectionRegister);
