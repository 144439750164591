import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './form.styl';
import Field from '../checkout/shared/forms/field';
import { CHARTE_PATH, CGU_PATH } from '../../constants/terms';

class RegisterForm extends Component {
  handleSubmit = event => {
    event.preventDefault();
    const { createAccountAction, email, password, cguAccepted } = this.props;
    createAccountAction({ email, password }, cguAccepted);
  };

  render() {
    const {
      registerErrors,
      onInputChange,
      email,
      password,
      cguAccepted,
      toggleCGU,
      wordings: {
        register: {
          sub_title,
          email_label,
          password_label,
          create_account,
          accept_cgu,
          cgu,
        },
        forms: { charte, charte_link },
      },
    } = this.props;

    return (
      <form onSubmit={this.handleSubmit} method="POST">
        <h5>{sub_title}</h5>
        <Field
          label={email_label}
          wrapperClassName={'stacked'}
          onChange={onInputChange}
          value={email}
          name="email"
          inputType="text"
          inputClassName="email-input"
          errors={registerErrors.email}
        />
        <Field
          label={password_label}
          wrapperClassName={'stacked'}
          onChange={onInputChange}
          value={password}
          name="password"
          inputType="password"
          errors={registerErrors.password}
        />
        <div className="checkbox-container">
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              checked={cguAccepted}
              name="cgu"
              onChange={toggleCGU}
            />
          </div>
          <label className={`${registerErrors.cgu ? 'error' : ''}`}>
            {accept_cgu}{' '}
            <a href={CGU_PATH} target="_blank">
              {cgu}
            </a>
          </label>
        </div>

        <div className="button-container">
          <button className="yellow-button" type="submit">
            {create_account}
          </button>
        </div>
        <span className="underform-charte">
          {charte}{' '}
          <a href={CHARTE_PATH} alt="Charte informatique" target="_blank">
            {charte_link}
          </a>
        </span>
      </form>
    );
  }
}

RegisterForm.defaultProps = {
  registerErrors: {},
};

RegisterForm.propTypes = {
  registerErrors: PropTypes.shape({}),
  wordings: PropTypes.shape({}).isRequired,
  onInputChange: PropTypes.func.isRequired,
  createAccountAction: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  cguAccepted: PropTypes.bool.isRequired,
  toggleCGU: PropTypes.func.isRequired,
};

export default RegisterForm;
